<template>
  <Residentials
    v-bind="$attrs"
    :key="project"
    :filter="filter"
    :project="project"
    :properties="properties"
  />
</template>

<script>
import Residentials from '@kvass/residentials'
import { eventBus } from '@kvass/flatfinder/src/Component'
import API from '@/api'

export default {
  data() {
    return {
      filter: { status: ['!draft'] },
      project: API.project,
      promise: null,
    }
  },

  methods: {
    setFilter(item = {}) {
      switch (item.id) {
        // Generatoren og krafttårnet
        case '63de481250ce150603d6f642':
          return (this.filter.number = '0-19')
        // Trekkeriet
        case '63de49bf50ce150603d6f849':
          return (this.filter.number = '20-100')

        default:
          return (this.filter = {})
      }
    },
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  mounted() {
    eventBus.on('onChange', item => this.setFilter(item))
  },

  components: {
    Residentials,
  },
}
</script>

<style lang="scss"></style>
