<template>
  <ButtonComponent
    ref="copy-bottom"
    class="copy-botton"
    @click="copy"
    v-bind="{ ...data }"
    success-label="Kopiert"
    :promise="promise"
  />
</template>

<script>
export default {
  data() {
    return {
      promise: null,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    copyContent: {
      type: String,
    },
  },
  methods: {
    copy() {
      this.promise = Promise.resolve(
        setTimeout(() => {
          navigator.clipboard.writeText(this.copyContent)
        }, 1000),
      )
    },
  },
}
</script>

<style lang="scss">
.elder-button {
  color: var(--background);
  border-radius: var(--border-radius, 3px);

  &__icon,
  &__label {
    padding: 0.6rem 1.2rem !important;
  }

  &__label {
    padding-left: 0 !important;
  }
  &--success {
    background-color: transparent;
    color: var(--primary);
    border: 2px solid var(--primary);
  }
}
</style>
