import { TransformTag } from '@/utils'
import moment from 'moment'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.subpage', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'article-section-slider',

        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),

            props: {
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        class: 'article-section',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'title',
            },
            data: {
              content: `<h1>${get('title')}</h1>`,
            },
          },
          {
            component: 'Text',
            props: {},
            data: {
              content: moment(get('publishedFrom')).format('YYYY-MM-DD'),
            },
          },
          {
            component: 'Text',
            props: {},
            data: {
              content: get('content'),
            },
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          class: 'additional-sections-article',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Section',
      props: {
        style: 'padding-top:4rem; padding-bottom: 3rem; ',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              justifyContent: 'center',
            },
            blocks: {
              default: [
                {
                  component: 'Copy',
                  props: {
                    copyContent: window.location.href,
                  },
                  data: {
                    label: 'Kopier lenke og del',

                    theme: 'primary',
                    icon: ['far', 'link'],
                    iconPlacement: 'left',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    ...(get('customFields.additional-sections', 'Root.item') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.icons', 'Root.item') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        class: 'features',
        width: 'medium',
        theme: get('customFields.icons-settings.theme', 'Root.item') || 'none',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns', 'Root.item')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color', 'Root.item')
                : 'inherit',

              borderTheme: get('customFields.icons-settings.border-theme', 'Root.item'),
              placement: get('customFields.icons-settings.placement', 'Root.item'),
              padding: '0',
            },
            data: {
              content: get('customFields.icons', 'Root.item'),
            },
            variant: get('customFields.icons-settings.variant', 'Root.item') || 'none',
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1', 'Root.item') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          class: 'additional-sections-article-button',
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.additional-icons', 'Root.item') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        class: 'features',
        width: 'medium',
        theme: get('customFields.additional-icons-settings.theme', 'Root.item') || 'none',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns:
                parseInt(get('customFields.additional-icons-settings.columns', 'Root.item')) || 4,
              iconBackgroundColor: get(
                'customFields.additional-icons-settings.show-icon-background-color',
              )
                ? get('customFields.icons-settings.icon-background-color', 'Root.item')
                : 'inherit',

              borderTheme: get('customFields.additional-icons-settings.border-theme', 'Root.item'),
              placement: get('customFields.additional-icons-settings.placement', 'Root.item'),
              padding: '0',
            },
            data: {
              content: get('customFields.additional-icons', 'Root.item'),
            },
            variant: get('customFields.additional-icons-settings.variant', 'Root.item') || 'none',
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
