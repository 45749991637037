import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.subpage', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  const flatfinder = get('flatfinders', 'Root.item').filter(
    flatfinder => flatfinder.id === get('customFields.flatfinder.id'),
  )

  return [
    {
      condition: !get('media.cover.length'),
      component: 'Section',
      props: {
        width: 'full',
        class: 'hero-spacing',
      },
    },
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        style: 'padding: 1.5rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'max-width: 37.5rem; margin: 0 auto;',
            },
            data: {
              content: get('content'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
        style: 'padding: 0',
      },
      blocks: {
        default: [
          {
            component: 'Flatfinder',
            props: {
              class: 'residential-flatfinder',
              preventFlatfinderNavigation: true,
            },
            data: {
              flatfinders: flatfinder,
            },
            blocks: {
              top: [
                {
                  component: 'Text',
                  props: {
                    class: 'custom-flatfinder__title',
                  },
                  data: {
                    content: get('customFields.flatfinder.title') || flatfinder.name,
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.site-settings.show-residentials'),
      props: {
        width: 'full',
        class: 'residential-table',
      },
      blocks: {
        default: [
          {
            component: 'Residentials',
            props: {
              statusLabels: {
                'rent.rent': 'Ledige lokaler',
                'rent.rented': 'Utleid',
              },
              showStatus: true,
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.site-settings.show-residentials'),
      props: {
        width: 'medium',
        class: 'residential-card',
      },
      blocks: {
        default: {
          component: 'ResidentialCard',
          variant: 'grid',
          data: {
            items: get('residentials', 'Root.item') || [],
          },
        },
      },
    },

    {
      component: 'Section',
      condition: get('customFields.site-settings.show-lead'),
      props: {
        class: 'residential-lead-section',
        width: 'small',
        style: '--section-width: 640px;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              textAlign: 'center',
              class: 'residential-lead-content',
              style: 'padding-bottom: 2rem',
            },
            data: {
              content: get('customFields.lead-content'),
            },
          },

          {
            component: 'Lead',
            props: {
              class: 'residential-lead',
              options: {
                placeholders: {
                  'contact.name': 'Fyll inn navn',
                  'contact.email': 'Fyll inn e-post',
                  'contact.phone': 'Fyll inn telefonnummer',
                  comment: this.$tc('message', 1),
                },
                fields: ['!title', '!contact.upsell', '!comment'],
                actionLabel: this.$t('send'),
              },
            },
            data: {
              project: get('id', 'Root.item'),
              reference: { onModel: 'Project', ref: get('id', 'Root.item') },
              privacyUrl: '/resources/juridisk/personvern-forbehold',
            },
          },
        ],
      },
    },
    {
      component: 'FlatfinderGoBack',
      data: {
        image: {
          url: 'https://assets.kvass.no/63f72ad8d44e137d56f9b733',
        },
        goBackUrl: '/utleie/utforsk-byggene',
        goBackTitle: 'Utforsk byggene',
      },
    },
  ]
}
