<template>
  <div v-if="itemsComp.length" class="residential-card">
    <div class="residential-card__wrapper">
      <a
        :href="`${item.path}`"
        :class="`residential-card__link residential-card__link-status--${item.status}`"
        v-for="(item, index) in itemsComp"
        :key="index"
      >
        <template v-if="$path('media.cover.0.url', item)">
          <div
            class="residential-card__image"
            :style="{ backgroundImage: `url(${item.media.cover[0].url})` }"
          ></div>

          <div class="residential-card__text">
            <h2>{{ item.name }}</h2>
            <div>
              <strong>BRA m² :</strong> {{ item.properties.find(i => i.key === 'btaM').value }}
            </div>
            <div>
              <strong> Bygg :</strong> {{ item.properties.find(i => i.key === 'bygg').value }}
            </div>
          </div>
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@kvass/flatfinder/src/Component'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },

  data() {
    return {
      filter: { status: ['!draft'] },
    }
  },
  computed: {
    itemsComp() {
      return this.data.items
        .filter(i => {
          if (!this.filter.number) return true
          let filterNumber = this.filter.number.split('-')

          return (
            parseInt(filterNumber[0]) <= parseInt(i.number) &&
            parseInt(i.number) <= parseInt(filterNumber[1])
          )
        })
        .sort((a, b) => {
          if (a.number < b.number) {
            return -1
          }
          if (a.number > b.number) {
            return 1
          }

          return 0
        })
    },
  },
  methods: {
    setFilter(item = {}) {
      switch (item.id) {
        // Generatoren og krafttårnet
        case '63de481250ce150603d6f642':
          return (this.filter.number = '0-19')
        // Trekkeriet
        case '63de49bf50ce150603d6f849':
          return (this.filter.number = '20-100')

        default:
          return (this.filter = {})
      }
    },
  },
  mounted() {
    eventBus.on('onChange', item => this.setFilter(item))
  },
}
</script>

<style lang="scss">
.residential-card {
  margin: 0 auto;
  line-height: 1.2;
  $cardSpacing: 2rem;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    $cardGap: 10px;
    gap: $cardGap;

    @include respond-above('phone') {
      flex-direction: row;
      --card-gap: 30px;
    }
  }

  &__link {
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    position: relative;

    color: var(--primary-contrast);
    background-color: rgb(69, 83, 96);
    text-decoration: none;
    transition: transform 0.3s, box-shadow 0.3s ease;
    &:hover {
      text-decoration: none;
      transform: scale(1.04);
    }

    &::after {
      content: 'Ledige lokaler';
      color: white;
      max-width: 150px;
      margin: 8px auto auto;
      background-color: var(--success);
      position: absolute;
      padding: 10px 20px;
      font-size: 0.7em;
      top: 1rem;
    }
  }
  &__link-status--rented {
    &::after {
      content: 'Utleid';
      background-color: var(--error);
    }
  }

  &__text {
    word-break: break-word;
    padding: 1em $cardSpacing;
  }

  &__image {
    width: 100%;
    min-height: 300px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>
