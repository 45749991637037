<template>
  <Loader
    theme="overlay"
    :key="$path('title', post)"
    :value="promises.fetch"
    class="subpage-subpage"
    :class="{
      'subpage-subpage__residential': residential,
      'page-has-no-cover': !$path('media.cover.length', post),
    }"
  >
    <Blocks v-if="item.posts" :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import Config from '@/config/subSubpage'
import { Blocks } from '@kvass/pagebuilder'

import Progress from '@/components/Progress.vue'
import ArticleCard from '@/components/ArticleCard.vue'
import ResidentialCard from '@/components/ResidentialCard.vue'
import Residentials from '@/components/Residentials.vue'
import Copy from '@/components/Copy.vue'

import FlatfinderGoBack from '@/components/FlatfinderGoBack'
import { animate, inView } from 'motion'

export default {
  props: {
    subpage: {
      type: String,
      default: 'post',
    },
    main: {
      type: String,
      default: 'post',
    },
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    customComponents() {
      return { Progress, ArticleCard, FlatfinderGoBack, ResidentialCard, Residentials, Copy }
    },
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']

      return Config[sources[0]].call(this)
    },
    residential() {
      if (!this.post.customFieldsConfig) return
      return this.$path('customFieldsConfig.0.source', this.post) === 'rental-residential'
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === this.subpage)

      const parent = this.item.posts.find(p => p.slug === this.main)
      const postParent = this.item.posts.find(p => p.slug === this.slug)

      // redirect
      if (!post || !parent || !postParent) {
        this.$router.push({ name: 'Project' })
      }

      if (
        post.customFieldsConfig[0].source === 'rental-residential' &&
        (parent.customFieldsConfig[0].source !== 'rental-areas' ||
          postParent.customFieldsConfig[0].source !== 'rental')
      ) {
        this.$router.push({ name: 'Project' })
      }

      const isArticle = post.customFieldsConfig[0].source === 'article'
      const isApplicable = ['rental-applicable', 'about-applicable'].includes(
        parent.customFieldsConfig[0].source,
      )
      const isChecked = (post.customFields['site-settings']?.type || []).some(el =>
        postParent.customFieldsConfig[0].source.includes(el),
      )

      if (isArticle && (!isApplicable || !isChecked)) return this.$router.push({ name: 'Project' })

      return post
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    async initAnimation() {
      inView('.hero', () => {
        animate(
          '.hero',
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
          { easing: 'linear' },
        )
      })
      inView('.hero__content', () => {
        animate('.hero__content', { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.4 })
      })

      const images = document.querySelectorAll('.kpb-gallery__item')
      if (images.length) {
        animate(
          images,
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
          { easing: 'linear' },
        )
      }

      const button = document.querySelectorAll('a[data-cta]')
      if (button.length) {
        animate(button, { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.6 })
      }
    },
  },
  created() {
    this.fetch().then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.subpage-subpage {
  &__residential {
    background-color: var(--primary);
    color: $beige;
  }
  .article-section {
    padding-block: 1.5rem;
    --section-padding-inline: 1.5rem;
    --section-width: 40.5rem;
  }

  .kpb-images-text-section {
    &__images {
    }

    &__content {
      @include respond-below('phone') {
        padding: 1.5rem !important;
      }
    }
  }

  .hero-spacing {
    padding-block: 60px;
    @include respond-below('phone') {
      padding-block: 10px;
    }
  }

  .residential-lead-section {
    background-color: $beige;
    color: var(--primary);
  }

  .residential-lead-content {
    h1,
    h2,
    h3 {
      font-weight: bold;
    }
  }

  .residential-lead {
    .lead__form {
      background-color: inherit;
      color: var(--primary);
      grid-gap: 3rem;

      .custom-fields {
        grid-gap: 3rem;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 0;
      }
      --vue-elder-border-radius: 0;
      --vue-elder-text-color: var(--primary);

      --vue-elder-border-color: transparent;

      ::placeholder {
        color: var(--primary);
      }

      :-ms-input-placeholder {
        color: var(--primary);
      }

      ::-ms-input-placeholder {
        color: var(--primary);
      }

      &-actions {
        margin-top: 0;

        .elder-button {
          color: var(--primary);
          border-color: var(--secondary-border, var(--secondary));
          background-color: var(--secondary);

          padding: var(--vue-elder-button-padding-y, 0.5rem) var(--vue-elder-button-padding-x, 1rem);
          border-radius: var(--border-radius, 3px);
          text-decoration: none;
          display: inline-block;
          min-width: 200px;
          transition: background-color 0.2s, color 0.3s;
          text-align: center;
          font-size: 1rem;

          @media (min-width: 768px) {
            font-size: 1.1rem;
          }

          &__icon {
            display: none;
          }

          &:before {
            display: none;
          }

          &:hover {
            background-color: rgb(255, 254, 97);
          }

          &:after {
            margin-left: 10px;
            width: 30px;
            height: 12px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.463' height='11.898' viewBox='0 0 29.463 11.898'%3E%3Cg id='Group_146' data-name='Group 146' transform='translate(22650.453 73.556)'%3E%3Cline id='Line_1' data-name='Line 1' x2='27.047' transform='translate(-22649.453 -67.606)' fill='none' stroke='%233f3a39' stroke-linecap='round' stroke-width='2'%3E%3C/line%3E%3Cpath id='Path_55' data-name='Path 55' d='M-22541.605-60.5l-4.535,4.535' transform='translate(-80.8 -7.107)' fill='none' stroke='%233f3a39' stroke-linecap='round' stroke-width='2'%3E%3C/path%3E%3Cpath id='Path_56' data-name='Path 56' d='M-22541.605-55.965l-4.535-4.535' transform='translate(-80.8 -11.642)' fill='none' stroke='%233f3a39' stroke-linecap='round' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }

      .elder-checkbox__label {
        font-weight: 500;
        a {
          color: var(--primary);
        }
      }

      .elder-checkbox__box {
        --border-radius: 0;
        background-color: inherit;
        border: 2px solid var(--primary);
      }

      .elder-input {
        &__label {
          display: none;
        }
        &__field {
          background-color: inherit;
          border: none;
          border-bottom: 1px solid var(--primary);

          &--focus {
            border-bottom: 2px solid var(--primary);
          }
        }
        &__element {
          padding: 0.5em;
        }
      }
    }
  }

  .title {
    h1 {
      font-weight: normal;
    }
  }

  .article-section-slider {
    padding-block: calc(8.2% + 120px) 0;
    --section-width: 1200px;
    --section-padding-inline: 0;

    @include respond-below('phone') {
      padding-block: 8.2% 0;
    }
  }

  .additional-sections-article {
    .kpb-images-text-section__images--layout-none {
      display: block;
    }

    .kpb-gallery__item-caption-wrapper {
      position: initial;
      padding: 1.5rem;
      padding-bottom: 0;
      background: inherit;
      margin: 0 auto;
      max-width: 40.5rem;
      font-size: 1rem;
      font-style: italic;
      color: var(--primary);
    }
  }

  .additional-sections-article-button {
    .kpb-text {
      a[target='_blank'] {
        margin-bottom: 1rem;
        @include respond-above('tablet') {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .features {
    .kpb-section__container {
      max-width: 60rem;
    }

    --section-padding-inline: 0;

    padding-block: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include respond-below('phone') {
      padding: 1.25rem 1.75rem;
    }
  }
  .residential-card {
    @include respond-above('phone') {
      display: none;
    }
  }

  .residential-table {
    @include respond-below('phone') {
      display: none;
    }

    padding: 0;
    margin: 40px 2vh 70px;

    .residentials {
      margin: 0 auto;
      width: auto;
      max-width: 75%;

      white-space: nowrap;
      border-collapse: collapse;

      &__header {
        display: none;
      }

      // remove Price
      .text--right {
        display: none;
      }

      &__scroller {
        background: none;
      }

      &__header {
        display: none;
      }

      &-actions {
        margin-left: auto;
        width: max-content;

        &__item {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          border-radius: 10rem;
          border: 1px solid transparent;

          &:hover {
            border-color: var(--primary-contrast);
          }
        }
      }
    }
  }

  .hero {
    opacity: 0;
    position: relative;

    &__slider {
    }

    &__layout {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;

      align-items: flex-end;

      width: 100%;
      height: 100%;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(60%, rgba(0, 0, 0, 0.2)),
          to(transparent)
        );
        background: -o-linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
        background: linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
        pointer-events: none;
      }
    }

    &__content {
      background: radial-gradient(rgba(0, 0, 0, 0.5) 0, transparent 60%);
      opacity: 0;
      color: $beige;
      margin: 0 auto;
      padding: 2rem 1.5rem;
      width: 100%;
      max-width: 28.125rem;
      text-align: center;

      h1 {
        font-size: 1.61rem;
      }
    }
  }
}
.route--article {
  .kpb-slider {
    img {
      object-position: top center;
    }
  }
}
</style>
