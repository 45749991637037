<template>
  <div class="flatfinder-go-back">
    <a class="flatfinder-go-back__link" v-if="data.image" :href="data.goBackUrl">
      <FontAwesomeIcon :icon="['fal', 'chevron-left']" class="flatfinder-go-back__icon" />
      <span class="flatfinder-go-back__text">Tilbake til {{ data.goBackTitle }}</span>

      <div
        class="flatfinder-go-back__img"
        :style="{ backgroundImage: 'url(' + data.image.url + ')' }"
      ></div>
    </a>
  </div>
</template>

<script>
export default {
  props: { data: Object },
}
</script>

<style lang="scss">
.flatfinder-go-back {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0;
  top: auto;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease;

  width: 100%;

  background: #fff;
  color: #000;
  height: 84px;
  z-index: 1000;

  &:hover {
    .flatfinder-go-back__img {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  @include respond-above('phone') {
    width: 230px;
    height: 152px;
    left: 12px;
    bottom: 20px;
    border-radius: 10px;
  }

  &__link {
    padding: 8px 7px 7px;

    color: #000;
    display: inline-flex;
    align-items: center;
    height: 100%;
    text-decoration: none;

    @include respond-above('phone') {
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__icon {
    width: 10px !important;
    height: 20px;
    margin-right: 10px;
    @include respond-above('phone') {
      display: none;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 6px;
    @include respond-below('phone') {
      display: none;
    }
  }

  &__img {
    min-height: 69px;
    width: 112.758px;
    position: relative;

    border-radius: 10px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    @include respond-above('phone') {
      min-height: 112px;
      width: 210.55px;
      box-shadow: unset;
    }
  }
}
</style>
